import { render, staticRenderFns } from "./myPoints.vue?vue&type=template&id=12e9b487&scoped=true"
import script from "./myPoints.vue?vue&type=script&lang=js"
export * from "./myPoints.vue?vue&type=script&lang=js"
import style0 from "./myPoints.vue?vue&type=style&index=0&id=12e9b487&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12e9b487",
  null
  
)

export default component.exports